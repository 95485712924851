import request from "./http"


/**
 * @description 获取项目
 *  @param {参数类型} 参数名称
 * @return 返回项目数据
 */
export function getProject(limit, page, data) {
    return request({
        url: `/api/admin/project/list?limit=${limit}&page=${page}`,
        method: "post",
        data
    })
}


/**
 * @description 新增项目
 *  @param {Object} data 表单数据
 * @return 新增成功
 */
export function addProject(data) {
    return request({
        url: `/api/admin/project/create`,
        method: "post",
        data
    })
}


/**
 * @description 修改项目
 *  @param {String} id 项目id
 *  @param {Object} data 表单数据
 * @return 修改成功
 */
export function updateProject(id, data) {
    return request({
        url: `/api/admin/project/update/${id}`,
        method: "post",
        data
    })
}


/**
 * @description 删除项目
 *  @param {String} id 表单数据
 * @return 删除成功
 */
export function delProject(id) {
    return request({
        url: `/api/admin/project/delete/${id}`,
        method: "post",
    })
}
<!--
* @FileDescription: 技师管理
* @Author: menfutong
* @Date: 2021/11/04
* @LastEditors: 最后更新作者
* @LastEditTime: 最后更新时间
-->
<template>
  <div class="store-manage">
    <!-- 查询表单 -->
    <CommonQueryFrom
      :queryForm="queryForm"
      :queryFormItemList="queryFormItemList"
      @_add="_add"
      @_query="_query"
      @_export="_export"
    ></CommonQueryFrom>
    <!-- 表格 -->
    <CommonTable
      :tableData="tableData"
      :columns="columns"
      :loading="loading"
      :pagingOptions="pagingOptions"
      @_del="_del"
      @_edit="_edit"
      @_sizeChange="_sizeChange"
      @_currentChange="_currentChange"
      @_handleSelectionChange="_handleSelectionChange"
    >
    </CommonTable>
    <!-- 弹框表单 -->
    <!-- <CommonDialogForm
      ref="form"
      :form="form"
      :rules="rules"
      :visible="visible"
      :title="dialogTitle"
      :formItemList="formItemList"
      @_Close="_Close"
      @_cancel="_cancel"
      @_confirm="_confirm"
      @handleAvatarSuccess="handleAvatarSuccess"
    > -->
    <!-- <el-select
        v-model.trim="form[projectList]"
        size="small"
        width="100%"
        :placeholder="item.placeholder"
      >
        <el-option
          v-for="(ite, ind) in projectList"
          :key="ind"
          :label="ite.name"
          :value="ite.id"
        >
        </el-option>
      </el-select> -->
    <!-- </CommonDialogForm> -->

    <el-dialog :title="dialogTitle" :visible.sync="visible" width="80%">
      <el-form
        ref="massagistForm"
        :model="massagistForm"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item label="技师姓名" prop="name">
          <el-row>
            <el-col :span="8">
              <el-input
                size="small"
                width="50%"
                clearable
                v-model="massagistForm.name"
                placeholder="请输入技师姓名"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select
            v-model.trim="massagistForm.sex"
            size="small"
            width="100%"
            placeholder="请输入性别"
            clearable
          >
            <el-option label="男" :value="1" />
            <el-option label="女" :value="0" />
          </el-select>
        </el-form-item>
        <el-form-item label="技师照片" prop="image">
          <el-upload
            ref="uploadAvatar"
            class="avatar-uploader"
            :action="url"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="massagistForm.image"
              :src="massagistForm.image"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="所属店铺" prop="storeId">
          <el-select
            v-model.trim="massagistForm.storeId"
            size="small"
            width="100%"
            placeholder="请输入所属店铺"
            clearable
            @change="storeSelectChange"
          >
            <el-option
              v-for="(ite, ind) in storeList"
              :key="ind"
              :label="ite.name"
              :value="ite.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="是否盲人" prop="isBlind">
          <el-select
            v-model.trim="massagistForm.isBlind"
            size="small"
            width="100%"
            placeholder="请输入是否是盲人"
            clearable
          >
            <el-option label="盲人" :value="1" />
            <el-option label="非盲人" :value="0" />
          </el-select>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-row>
            <el-col :span="8">
              <el-input
                size="small"
                clearable
                v-model="massagistForm.phone"
                placeholder="请输入技师手机号"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="从业年限" prop="workTime">
          <el-row>
            <el-col :span="4">
              <el-input
                size="small"
                clearable
                v-model="massagistForm.workTime"
                placeholder=""
              >
                <template slot="append">年</template>
              </el-input>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item
          label-width="auto"
          v-for="(item, index) in massagistForm.projectItems"
          :key="index"
        >
          <el-col :span="8">
            <el-form-item
              :label="'选择项目'"
              :prop="'projectItems.' + index + '.id'"
              :rules="{
                required: true,
                message: '选择项目不能为空',
                trigger: 'blur',
              }"
            >
              <el-select
                v-model="item.id"
                size="small"
                clearable
                placeholder="请选择项目"
                @change="selectChange"
                :disabled="!massagistForm.storeId"
              >
                <!--添加显示隐藏逻辑，使用v-show-->
                <!--area.province==item.code 表示有初始值时，则显示该值，无初始值时使用下面规则-->
                <!--!formInline.areas.find(t=>t.province==item.code) 表示需要展示的数据不存在于已选列表-->
                <el-option
                  v-for="(project, index) in projectList"
                  :key="index"
                  :label="project.name"
                  :value="project.id"
                  v-show="
                    item.id == project.id ||
                    !massagistForm.projectItems.find((t) => t.id === project.id)
                  "
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="'项目价格'"
              :prop="'projectItems.' + index + '.money'"
              style="margin-left: 20px"
              :rules="{
                required: true,
                message: '价格不能为空',
                trigger: 'blur',
              }"
            >
              <el-input
                size="small"
                clearable
                v-model="item.money"
                oninput="value=value.replace(/[^0-9.]/g,'')"
                maxlength="8"
                placeholder="请输入项目价格"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <el-button
                type="warning"
                size="small"
                @click.prevent="removeProject(item)"
                style="margin-left: 10px"
                >删除</el-button
              >
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item>
          <!-- <el-button type="primary" @click="submitForm('item')"
            >提交</el-button
          > -->
          <el-button
            type="primary"
            size="small"
            @click="addProject(massagistForm)"
            >添加项目</el-button
          >
          <el-button size="small" @click="resetForm('massagistForm')"
            >重置</el-button
          >
        </el-form-item>
        <el-form-item label="介绍" prop="introduction">
          <el-input
            type="textarea"
            size="small"
            autosize
            clearable
            v-model="massagistForm.introduction"
            placeholder="请输入技师介绍"
          ></el-input>
        </el-form-item>
        <el-form-item label="获得荣誉" prop="honors">
          <el-upload
            class="upload-demo"
            list-type="picture"
            :action="url"
            :file-list="fileList"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :limit="limit"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，照片个数为{{ limit }}张且不超过500kb
            </div>
          </el-upload>
        </el-form-item>
        <!-- <el-form-item label="擅长领域" prop="skill">
          <el-input
            type="textarea"
            size="small"
            autosize
            clearable
            v-model="massagistForm.skill"
            placeholder="请输入技师擅长领域"
          ></el-input>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel('form')">取消</el-button>
        <el-button type="primary" @click="confirm('massagistForm')"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 引入侧边栏json
const formItemList = require("./formItemList")

// 引入接口
import {
  getClassifyListByPage,
  addProjectClassify,
  delProjectClassify,
  updateProjectClassify,
} from "../../api/project-classify-manage"

import {
  getListByPage,
  addMassagist,
  delMassagist,
  updateMassagist,
} from "../../api/massagist-manage"

import { getProjectAll } from "../../api/service-items-manage"

import {
  getStoreOptions,
  getProjectOptions,
  getProjectOptionsBySid,
} from "../../api/select-options"

import { updateUser } from "../../api/user"

// 引入方法
import { getPhoneValidator } from "../../utils/tool"

import http from "../../api/http"

export default {
  name: "StoreManage",

  // computed: {
  //   dynamicForm() {
  //     return {
  //       dynamicForm: this.projectItems,
  //     }
  //   },
  // },
  data() {
    return {
      //项目动态表单项
      // dynamicForm: {
      //   projectItems: [{ id: "", money: "" }],
      // },

      // 查询表单
      queryForm: {
        classifyName: "",
      },

      // 查询表单项
      queryFormItemList: [
        {
          type: "input",
          prop: "classifyName",
          placeholder: "请输入要查询的分类名称",
        },
      ],

      // 列接受的参数
      columns: [
        { label: "技师姓名", prop: "name" },
        { label: "性别", prop: "sex" },
        { label: "技师照片", prop: "image", width: 120 },
        { label: "手机号", prop: "phone", width: 120 },
        { label: "所属店铺", prop: "storeName", width: 120 },
        { label: "评分", prop: "score" },
        { label: "技师介绍", prop: "introduction", width: 300 },
        { label: "从业年限", prop: "workTime" },
        // { label: "擅长", prop: "skill", width: 300 },
        { label: "预约次数", prop: "orderNum" },
        { label: "技师等级", prop: "level" },
        { label: "创建时间", prop: "createTime", width: 120 },
      ],

      // 具体数据
      tableData: [],

      // 选择的表格数据
      selectChangeTableData: [],

      // 分页
      pagingOptions: {
        limit: 10,
        page: 1,
        total: 0,
      },

      // 加载
      loading: false,

      // 弹框标题
      dialogTitle: "添加技师",

      // 弹框显示
      visible: false,

      // 表单
      massagistForm: {
        name: "",
        image: "",
        storeId: "",
        isBlind: "",
        phone: "",
        workTime: "",
        projectItems: [{ id: "", money: 0 }],
        introduction: "",
        // skill: "1",
        honors: [],
      },

      project: "",
      money: "",

      // 校验
      rules: {
        name: [
          { required: true, message: "技师姓名不能为空", trigger: "change" },
        ],
        sex: [{ required: true, message: "性别不能为空", trigger: "change" }],
        // image: [
        //   { required: true, message: "技师照片不能为空", trigger: "change" },
        // ],
        storeId: [
          { required: true, message: "店铺不能为空", trigger: "change" },
        ],
        isBlind: [
          { required: true, message: "是否盲人不能为空", trigger: "change" },
        ],
        // phone: [
        //   { required: true, validator: getPhoneValidator, trigger: "change" },
        // ],
        workTime: [
          { required: true, message: "从业年限不能为空", trigger: "change" },
        ],
        introduction: [
          { required: true, message: "技师介绍不能为空", trigger: "change" },
        ],
        // skill: [
        //   { required: true, message: "擅长领域不能为空", trigger: "change" },
        // ],
      },

      // 表单数据
      formItemList: formItemList,

      baseURL: "",

      //项目列表
      projectList: [],

      url: "",

      storeList: [],

      limit: 3,

      //技师荣誉轮播图数组
      fileList: [],
      addLine: false,
    }
  },

  created() {},

  filters: {
    sexFilter(i) {
      let sexStatus = {
        0: "女",
        1: "男",
      }
      return sexStatus[i]
    },
  },

  mounted() {
    this.NODE_ENV = process.env.NODE_ENV === "development"
    const baseURL = http.defaults.baseURL
    this.url = `${baseURL}/api/user/store/doUpload`
    this.getData()
    this.getStore()

    console.log("massagistForm", this.massagistForm)
  },

  methods: {
    selectChange(e) {
      console.log("e", e)
      const findIndex = this.projectList.findIndex((_e) => _e.id === e)
      console.log("findIndex", findIndex)
      // this.projectList.splice(findIndex, 1)
    },

    storeSelectChange(e) {
      console.log("e", e)
      this.getProjectList(this.massagistForm.storeId || "")
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    removeProject(item) {
      var index = this.massagistForm.projectItems.indexOf(item)
      if (index !== -1) {
        this.massagistForm.projectItems.splice(index, 1)
      }
    },

    addProject(f) {
      this.massagistForm.projectItems.push({ id: "", money: 0 })
    },

    // 查询
    _query(form) {
      this.getData()
    },

    // 添加
    _add() {
      //置空一下数据
      this.fileList = []

      this.massagistForm = {
        name: "",
        image: "",
        storeId: "",
        isBlind: "",
        phone: "",
        workTime: "",
        projectItems: [{ id: "", money: 0 }],
        introduction: "",
        // skill: "",
        honors: [],
      }

      if (this.addLine) {
        console.log("addLines", this.selectChangeTableData[0])

        this.massagistForm = this.selectChangeTableData[0]

        this.getProjectList(this.massagistForm.storeId || "")

        this.massagistForm.id = ""

        this.massagistForm.projectList = []

        this.massagistForm.userId = ""

        // this.massagistForm.storeIds = this.massagistForm.storeIds || [this.massagistForm.storeId]
      }

      console.log("添加", this.massagistForm)
      this.visible = true
      this.dialogTitle = "添加技师"

      this.$nextTick(() => {
        this.$refs.massagistForm.clearValidate()
      })
    },

    // 导出
    _export(form) {
      // console.log("导出", form)
      import("../../vendor/Export2Excel").then((excel) => {
        const tHeader = getExcelLabelOrProp("编号", "label", this.columns)
        const filterVal = getExcelLabelOrProp("serial", "prop", this.columns)
        const list =
          this.selectChangeTableData.length > 0
            ? this.selectChangeTableData
            : this.tableData
        console.log(
          "_export",
          this.selectChangeTableData.length,
          this.selectChangeTableData
        )
        console.log("this.tableData", this.tableData)
        console.log("list", list)
        const data = this.formatJson(filterVal, list)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "技师管理",
          bookType: "xlsx",
        })
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]))
    },

    // 多选事件
    _handleSelectionChange(val) {
      // console.log("多选事件", val)
      this.selectChangeTableData = val
      console.log("length", this.selectChangeTableData.length)
      this.addLine = this.selectChangeTableData.length === 1
    },

    // 修改
    _edit(row) {
      console.log("修改", row)
      this.dialogTitle = "修改技师"
      this.visible = true
      this.massagistForm = Object.assign({}, row)
      this.fileList = row.honors ? JSON.parse(row.honors) : []
      this.getProjectList(row.storeId || "")
      this.massagistForm.projectList = []
      if (!this.massagistForm.projectItems) {
        this.massagistForm.projectItems = []
      }

      this.$nextTick(() => {
        this.$refs.massagistForm.clearValidate()
      })
    },

    // 删除
    async _del(row) {
      this.$confirm("你确定要删除此技师吗？, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await delMassagist(row.id)
          if (res.code !== 200) {
            return this.$message({ message: "删除失败", type: "error" })
          }
          this.$forceUpdate()
          this.$message({
            type: "success",
            message: "删除成功!",
          })
          this.getData()
          this.$forceUpdate()
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          })
        })
    },

    // 每页多少条事件
    _sizeChange(val) {
      this.pagingOptions.limit = val
      this.getData()
    },

    // 当前页事件
    _currentChange(val) {
      this.pagingOptions.page = val
      this.getData()
    },

    // 取消
    cancel() {
      this.closeForm()
    },

    // 确定
    confirm() {
      // this.massagistForm.projectItems = [...this.dynamicForm.projectItems]
      console.log("确定", this.massagistForm)
      // console.log("123", JSON.stringify(this.massagistForm.projectItems))

      // for (let i = 0; i < this.massagistForm.projectItems.length; i++) {
      //   this.massagistForm["projectItem[" + i + "].money"] = this.massagistForm.projectItems[i].money
      //   this.massagistForm["projectItem[" + i + "].project"] = this.massagistForm.projectItems[i].project
      // }
      this.$refs.massagistForm.validate(async (valid) => {
        if (valid) {
          !this.massagistForm.id ? this.add() : this.edit()
        } else {
          return false
        }
      })
    },

    // 添加接口
    async add() {
      try {
        const res = await addMassagist(this.massagistForm)
        console.log("添加接口", res)
        if (res.code !== 200) {
          return this.$message({
            showClose: true,
            message: "添加技师失败",
            type: "error",
          })
        }
        this.$message({
          showClose: true,
          message: "添加技师成功",
          type: "success",
        })
        this.visible = false
        this.getData()
      } catch (error) {
        this.visible = false
        this.$message({
          showClose: true,
          message: "添加技师失败",
          type: "error",
        })
      }
    },

    // 修改接口
    async edit() {
      try {
        const res = await updateMassagist(
          this.massagistForm.id,
          this.massagistForm
        )
        // console.log("修改接口", res)
        if (res.code !== 200) {
          return this.$message({
            showClose: true,
            message: "修改技师失败",
            type: "error",
          })
        }
        this.$message({
          showClose: true,
          message: "修改技师成功",
          type: "success",
        })
        this.visible = false
        this.getData()
      } catch (error) {}
    },

    // 关闭表单
    closeForm() {
      this.$nextTick(() => {
        this.$refs.massagistForm.clearValidate()
      })
      this.visible = false // 关闭弹框
    },

    // 获取数据
    async getData() {
      this.loading = true
      const { limit, page } = this.pagingOptions
      try {
        const res = await getListByPage(limit, page, {
          classifyName: this.queryForm.classifyName,
        })
        this.loading = false
        this.tableData = res.data.list
        this.pagingOptions.total = res.data.total
      } catch (error) {
        this.loading = false
        this.tableData = []
        this.pagingOptions.total = 0
        this.$message({
          showClose: true,
          message: "获取数据失败",
          type: "error",
        })
      }
    },

    // 获取所属店铺
    async getStore() {
      try {
        const res = await getStoreOptions()
        // const index = this.formItemList.findIndex((_) => _.prop === "storeId")
        // this.formItemList[index].options = res.data.records
        this.storeList = res.data.records
        // console.log("formItemList", this.formItemList[index].options, index)
      } catch (error) {
        console.log(error)
      }
    },

    //根据当前店铺获取项目列表
    async getProjectList(storeId) {
      try {
        const res = await getProjectOptionsBySid(storeId)
        this.projectList = res.data
      } catch (error) {
        console.log(error)
      }
    },

    /**
     * @description 上传图片
     *  @param {Object} file 文件
     */
    handleAvatarSuccess(res, file) {
      // this.massagistForm.avatar = http.defaults.baseURL + file.response
      this.massagistForm.image = file.response
    },

    // 上传照片之前
    beforeAvatarUpload(file) {
      // console.log("上传照片之前", file)
      const isJPG = file.type === "image/jpeg"
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!")
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!")
      }
      return isJPG && isLt2M
    },

    // 多张照片删除
    handleRemove(file, fileList) {
      console.log("arguments", file, fileList)
      const list = fileList
      const arr = []
      this.massagistForm.honors = fileList
      this.form.imagesInfos = JSON.stringify(arr)
      // console.log("bannerMapList", this.massagistForm.bannerMapList)
      console.log("honors", this.massagistForm.honors)
    },

    // 上传多张照片成功
    handleSuccess(response, file, fileList) {
      console.log("handleSuccess", response, file, fileList)

      const list = fileList.map((_) => _.response || _.url)
      const arr = []
      for (let i = 0; i < fileList.length; i++) {
        var obj = {}
        obj.name = fileList[i].name
        obj.url = fileList[i].response || arg[2][i].url
        arr.push(obj)
      }
      this.massagistForm.honors = JSON.stringify(arr)
      console.log("honors", this.massagistForm.honors)
      // this.form.imagesInfos = list
    },
  },
}
</script>

<style lang="scss" scoped>
.store-manage {
  height: 100%;
  padding: 0 16px 0 16px;
  background-color: #fff;
}
.el-textarea__inner {
  font-size: 14px;
  font-family: Arial;
}
</style>
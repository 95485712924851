import request from "./http"

/**
 * @description 获取技师分页列表
 *  @param  {String} limit 多少页
 *  @param  {String} page 第几页
 *  @param  {Object}}data 参数
 * @return 返回店铺数据
 */
export function getListByPage(limit, page, data) {
  return request({
    url: `/api/admin/massagist/list?limit=${limit}&page=${page}`,
    method: "get",
    data,
  })
}

/**
 * @description 新增技师
 *  @param {Object} data 表单数据
 * @return 新增成功
 */
export function addMassagist(data) {
  return request({
    url: `/api/user/store/storeCreateMassagist`,
    method: "post",
    data,
  })
}

/**
 * @description 修改技师
 *  @param {String} id 项目分类id
 *  @param {Object} data 表单数据
 * @return 修改成功
 */
export function updateMassagist(id, data) {
  return request({
    url: `/api/admin/massagist/update/${id}`,
    method: "post",
    data,
  })
}

/**
 * @description 删除技师
 *  @param {String} id 表单数据
 * @return 删除成功
 */
export function delMassagist(id) {
  return request({
    url: `/api/admin/massagist/delete/${id}`,
    method: "post",
  })
}
